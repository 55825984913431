import '../styles/main.scss';

import { graphql } from 'gatsby';
import React from 'react';

import groq from 'groq';
import ModulesContent, { Module, ModuleBackgroundColor } from '../components/ModulesContent';
import PageSEO from '../components/PageSEO';
import Hero, { HeroProps } from '../components/ui/Hero';
import SanityLayout from '../components/ui/SanityLayout';
import CTAModule from '../components/ui/modules/CTAModule';
import { useURLParamsContext } from '../contexts/URLParamsContext';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';

export const query = graphql`
  fragment Hero on SanityHpWebsiteHero {
    superTitle
    title
    subtitle
    text
    image {
      ...SanityImage
    }
    button {
      ...ButtonLink
    }
    reviews
    showClientBrandsLogos
    textAndImageAlignment
    textAlignment
    imageFitOption
  }

  query GetPageById($id: String!) {
    sanityHpWebsitePage(_id: { eq: $id }) {
      title
      slug {
        current
      }
      backgroundColor
      hero {
        ...Hero
      }
      modules {
        ...Modules
      }
      ctaSection {
        title
        text
        hideTextFieldInsteadOfUsingDefault
        button {
          ...ButtonLink
        }
        hideCtaSection
      }
      seo {
        ...SEO
      }
    }
  }
`;

const groqQuery = groq`{
  "sanityHpWebsitePage": *[_id == $id][0] {
     ...
  }
}`;

interface QueryData {
  sanityHpWebsitePage: {
    title: string;
    slug: {
      current: string;
    };
    backgroundColor: ModuleBackgroundColor;
    hero: HeroProps;
    ctaSection?: {
      title?: string;
      text?: string;
      hideTextFieldInsteadOfUsingDefault?: boolean;
      button?: ButtonLinkType;
      hideCtaSection?: boolean;
    };
    modules: Array<Module>;
    seo: LocalizedSEO;
  };
}

const Page = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({ data }): React.ReactElement => {
    const { title, slug, backgroundColor, hero, modules, ctaSection, seo } =
      data.sanityHpWebsitePage;

    const { ctaParam } = useURLParamsContext();

    return (
      <SanityLayout pageTheme={backgroundColor}>
        <PageSEO defaultTitle={slug.current === '/' ? '' : title} pageSEO={seo} />
        <Hero {...hero} pageColor={backgroundColor} />
        <ModulesContent modules={modules} pageTheme={backgroundColor} />
        {!ctaSection?.hideCtaSection && ctaParam !== 'none' && (
          <CTAModule
            previousModuleBgColor={
              modules[modules.length - 1].backgroundColor
                ? modules[modules.length - 1].backgroundColor
                : backgroundColor
            }
            title={ctaSection?.title}
            text={
              ctaSection?.text || (ctaSection?.hideTextFieldInsteadOfUsingDefault ? ' ' : undefined)
            }
            button={ctaSection?.button}
          />
        )}
      </SanityLayout>
    );
  },
);

export default Page;
