import React from 'react';

import { useURLParamsContext } from '../../contexts/URLParamsContext';
import { useActivateGoogleOptimize, useStoreUTMParamsOnLoad } from '../../utils/hooks';
import { ModuleBackgroundColor } from '../ModulesContent';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  pageTheme: ModuleBackgroundColor;
  children: React.ReactNode;
  headerConfig: HeaderProps;
  footerConfig: FooterProps;
}

const Layout = ({
  pageTheme,
  children,
  headerConfig,
  footerConfig,
}: LayoutProps): React.ReactElement => {
  const isHidden = useActivateGoogleOptimize();

  useStoreUTMParamsOnLoad();

  const { noNavParam } = useURLParamsContext();
  const isNavHidden = noNavParam === 'default' || noNavParam === 'all';

  return (
    <div className={styles.container} style={isHidden ? { opacity: 0 } : undefined}>
      <Header {...headerConfig} hideMenuEntries={isNavHidden}></Header>
      <div
        role="main"
        className={styles.main}
        data-theme={(pageTheme === 'dark' && 'dark') || (pageTheme === 'light' && 'light') || ''}
      >
        {children}
      </div>
      <Footer
        {...footerConfig}
        hideLinksBlock={isNavHidden}
        hideNewsletterBlock={isNavHidden}
      ></Footer>
    </div>
  );
};

export default Layout;
